import React from "react";

export default function Section5() {
  return (
    <section className="px-4 lg:px-8 py-20">
      <div>
        <div className="flex flex-col  md:flex-row justify-between md:gap-10 items-center">
          <div className="md:w-1/2">
            <h2 className="text-3xl md:text-4xl font-bold font-nunito text-gray-500 lg:leading-tight mb-2.5">
              <strong className="text-acesso">Embaixador:</strong>
              <br />
              Mauricio Benvenutti
            </h2>
            <p className="text-gray-800 font-normal text-base mt-8">
              Adentre o universo do Energy Advisor com o acompanhamento dedicado
              de Maurício Benvenutti, renomado especialista em energia e
              tecnologia.
              <br />
              <br />
              Sua experiência e visão estratégica contribuem para a formação de
              profissionais preparados para os desafios e oportunidades do
              mercado. Descubra o caminho para o sucesso com Maurício ao seu
              lado.
            </p>
          </div>
          <picture className="flex justify-center ">
            <img
              src="/images/image-section-5.png"
              alt="Mauricio Benvenutti"
              className="w-10/12"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </section>
  );
}
