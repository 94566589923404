import React from "react";

export default function Section4() {
  return (
    <>
      <section className="bg-[url('/images/bg-section-4.png')] bg-cover bg-center flex flex-col justify-center items-center px-4 lg:px-8 py-14">
        <img
          src="/images/diagram-section-4.png"
          alt="Diagrama"
          className="mr-8 lg:mr-20"
        />
      </section>
    </>
  );
}
