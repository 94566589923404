//Importação React
import React from "react";
import { FiMail, RiMailFill, RiWhatsappFill } from "react-icons/ri";
export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="p-4 bg-grayDark sm:p-6">
        <div className="md:flex md:justify-between px-4 lg:px-8 py-8">
          <div className=" flex items-center justify-between w-full">
            <a href="/">
              <img
                src="/images/logo/logo-energy-advisor-white.png"
                className="h-14"
                alt="Logo Energy Advisor"
              />
            </a>
            <div className="flex flex-col gap-5">
              <span className="text-gray-50 text-sm font-medium">
                ATENDIMENTO
              </span>
              <div className="flex items-center gap-10">
                <a
                  href="https://wa.me/915709272"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Fale conosco por Whastsapp"
                >
                  <img
                    src="/images/icons/icon-whatsapp.svg"
                    alt="WhatsApp"
                    className="w-9 hover:opacity-60 transform transition-all ease-out duration-300"
                  />
                </a>
                <a
                  href="https://wa.me/915709272"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Fale conosco por Whastsapp"
                >
                  <img
                    src="/images/icons/icon-phone.svg"
                    alt="Telefone"
                    className="w-9 hover:opacity-60 transform transition-all ease-out duration-300"
                  />
                </a>
                <a href="mailto:contato@acessoenergia.com.br">
                  <img
                    src="/images/icons/icon-mail.svg"
                    alt="Email"
                    className="w-9 hover:opacity-60 transform transition-all ease-out duration-300"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-acesso px-14 py-2">
        <span className="text-gray-800 text-sm font-bold font-roboto">
          Criado e desenvolvido por Acesso Energia © {year} Acesso Energia.
          Todos direitos reservados
        </span>
      </div>
    </footer>
  );
}
