//Importação React
import React from "react";

export default function HeaderEnergyAdvisor() {
  return (
    <header className="bg-[url('/images/bg-home.png')] bg-cover bg-center flex flex-col justify-center items-center px-4 lg:px-8 py-14">
      <div className="mb-8 flex flex-col items-center justify-center mt-10">
        <h1 className="text-4xl md:text-5xl font-bold font-nunito text-white lg:leading-tight mb-2.5 text-center">
          Quer saber como é a jornada do Energy Advisor e como prospectar e
          qualificar os leads?
        </h1>
        <p className="text-gray-200 font-roboto text-base lg:text-lg text-center max-w-3xl">
          Faça seu cadastro, fique por dentro de todas as novidades que já
          estamos fazendo, e saia na frente no mercado para se tornar um Energy
          Advisor.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center mb-14 gap-8">
        <a href="#contato">
          <button
            type="button"
            className="relative inline-flex group items-center justify-center py-2.5 px-4 bg-acesso rounded text-white text-sm md:text-base font-nunito font-extrabold outline-none focus:ring-4 focus:ring-gray-400 shadow-lg transform active:scale-x-75  hover:bg-acesso/90 trasnform transition-all duration-300"
          >
            Faça agora seu cadastro
          </button>
        </a>
        <button className="bg-transparent text-lg font-semibold text-acesso hover:opacity-80">
          Saiba mais
        </button>
      </div>
    </header>
  );
}
