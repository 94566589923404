import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import NumberFormat from "react-number-format";

const getFieldCSSClasses = (touched, errors) => {
  const classes = [
    "w-full h-11 pl-3 pr-8 mt-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-gray-400 focus:border-2 placeholder:text-gray-400 disabled:bg-gray-100 disabled:text-opacity disabled:text-gray-600 disabled:text-opacity-60 disabled:border-opacity-70 disabled:bg-opacity-70"
  ];
  if (touched && errors) {
    classes.push("border-2 border-red-500");
  }

  if (touched === true && errors === undefined) {
    classes.push("border-2 border-green-500");
  }

  return classes.join(" ");
};

export default function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-gray-500 text-sm font-semibold"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div className="">
          <input
            type={type}
            {...field}
            {...props}
            className={getFieldCSSClasses(
              touched[field.name],
              errors[field.name]
            )}
          />
          {withFeedbackLabel && errors[field.name] && (
            <FieldFeedbackLabel
              error={errors[field.name]}
              touched={touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}

export function InputMesesDados({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <NumberFormat
        suffix={" meses"}
        value={field.value}
        onValueChange={val => {
          form.setFieldValue(field.name, val.floatValue);
        }}
        className={getFieldCSSClasses(
          form.touched[field.name],
          form.errors[field.name]
        )}
        {...props}
      />
      {withFeedbackLabel && form.errors[field.name] && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          touched={form.touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function InputSazo({ field, form, label, type = "text", ...props }) {
  const { disabled } = props;

  function getDivCSSClasses() {
    const classes = [
      "flex items-center justify-center py-2 px-4 bg-gray-50 border-gray-200 border-b"
    ];

    if (label !== "dez") {
      classes.push("border-r");
    }

    return classes.join(" ");
  }

  function getFieldCSSClasses() {
    const classes = ["w-full border-gray-200"];

    if (label !== "dez") {
      classes.push("border-r");
    }

    return classes.join(" ");
  }

  return (
    <div className="flex flex-col">
      <div className={getDivCSSClasses()}>
        <span className="text-xs font-normal text-gray-600">{label}</span>
      </div>
      <div className={getFieldCSSClasses()}>
        <NumberFormat
          decimalSeparator=","
          thousandSeparator="."
          value={field.value}
          onValueChange={val => {
            form.setFieldValue(field.name, val.floatValue);
          }}
          className={
            disabled
              ? "w-full text-xs font-semibold text-gray-600 text-center border-0 ring-0 focus:ring-0 bg-gray-200"
              : "w-full text-xs font-semibold text-gray-600 text-center border-0 ring-0 focus:ring-0"
          }
          {...props}
        />
      </div>
    </div>
  );
}
