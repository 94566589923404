import React from "react";
export default function Nav() {
  return (
    <nav className="flex justify-between items-center flex-1 px-3 lg:px-8">
      <img
        src="/images/logo/logo-energy-advisor.png"
        alt="Logo Enegy Advisor"
        className="w-1/3 md:w-48 mb-3"
      />
      <a href="#contato">
        <button className="relative inline-flex group items-center justify-center py-2.5 px-6 bg-acesso rounded text-white text-sm font-nunito font-bold outline-none focus:ring-4 focus:ring-gray-400 transform active:scale-x-75 transition-transform hover:bg-acesso/80 mt-1">
          <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-full group-hover:h-24 opacity-10"></span>
          Carregue sua conta de Energia
        </button>
      </a>
    </nav>
  );
}
