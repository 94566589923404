import React from "react";

export default function PalmaMao() {
  return (
    <>
      <section className="px-4 lg:px-8 py-10 flex items-center justify-center mb-20 mt-10">
        <div>
          <div className="flex flex-col  md:flex-row justify-between items-center">
            <div className="md:w-1/2">
              <h2 className="text-3xl md:text-4xl font-bold font-nunito text-gray-800 lg:leading-tight mb-2.5">
                <strong className="text-acesso">
                  Tudo que seus energy advisors precisam
                </strong>{" "}
                na palma da mão
              </h2>
              <p className="text-gray-800 font-normal text-lg mt-8">
                Nosso aplicativo tem todos os recursos necessáriospara seu time
                acompanhar as vendas:
              </p>
              <ul className="list-disc ml-5 mt-6 text-gray-800 font-normal text-lg">
                <li>Gestão de leads e clientes</li>
                <li>Análise da conta de luz</li>
                <li>Gerador automático de proposta</li>
                <li>Organizador de tarefas</li>
                <li>Comissionamento</li>
              </ul>

              <a href="#contato" className="flex md:block justify-center">
                <button
                  type="button"
                  className="relative inline-flex group items-center justify-center py-2.5 px-4 bg-neutral-500 rounded text-white text-base font-nunito font-extrabold outline-none focus:ring-4 focus:ring-gray-400 shadow-lg transform active:scale-x-75 transition-transform hover:bg-zinc-800 mt-8 md:mt-14 md:mb-0 mb-10"
                >
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-full group-hover:h-32 opacity-10"></span>
                  Faça agora seu cadastro
                </button>
              </a>
            </div>
            <picture className="flex justify-center md:mr-10">
              <img
                src="/images/solucoes/energyAdvisor/app.png"
                alt="Imagem que mostra planilhas, documentos e emails que se tornam uma plataforma completa"
                className="w-10/12"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </section>
    </>
  );
}
