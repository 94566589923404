import React from "react";

export default function GerenciarVendas() {
  return (
    <>
      <header className="flex flex-col justify-center items-center px-6 lg:px-14 2xl:px-48 py-24">
        <div className="mb-8 flex flex-col items-center justify-center mt-10">
          <h2 className="text-4xl md:text-5xl font-bold font-nunito text-gray-800 lg:leading-tight mb-2.5 text-center">
            <strong className="text-acesso">Muito apoio e benefícios </strong>
            para você vender mais
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-7">
          <div className="flex gap-5">
            <img
              className="w-10 md:w-14 h-10 md:h-14 mt-3"
              src="/images/icons/icon-1.png"
              alt="Icon 1"
            />
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <h3 className="text-neutral-600 text-xl md:text-2xl font-semibold  leading-loose">
                Comissionamento e Bônus
              </h3>
              <p className=" text-gray-800 text-base md:text-lg font-normal ">
                Seja recompensado pelo seu esforço e dedicação em trazer
                soluções eficazes para nossos clientes.
              </p>
            </div>
          </div>
          <div className="flex gap-5">
            <img
              className="w-10 md:w-14 h-10 md:h-14 mt-3"
              src="/images/icons/icon-2.png"
              alt="Icon 2"
            />
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <h3 className="text-neutral-600 text-xl md:text-2xl font-semibold  leading-loose">
                Leads Qualificados
              </h3>
              <p className=" text-gray-800 text-base md:text-lg font-normal ">
                Receba contatos valiosos por região para impulsionar suas
                oportunidades de sucesso.
              </p>
            </div>
          </div>
          <div className="flex gap-5">
            <img
              className="w-10 md:w-14 h-10 md:h-14 mt-3"
              src="/images/icons/icon-3.png"
              alt="Icon 3"
            />
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <h3 className="text-neutral-600 text-xl md:text-2xl font-semibold  leading-loose">
                Suporte Técnico
              </h3>
              <p className=" text-gray-800 text-base md:text-lg font-normal ">
                Conte com uma equipe dedicada para auxiliá-lo em todas as etapas
                do processo.
              </p>
            </div>
          </div>
          <div className="flex gap-5">
            <img
              className="w-10 md:w-14 h-10 md:h-14 mt-3"
              src="/images/icons/icon-4.png"
              alt="Icon 4"
            />
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <h3 className="text-neutral-600 text-xl md:text-2xl font-semibold  leading-loose">
                Capacitação Especializada
              </h3>
              <p className=" text-gray-800 text-lg font-normal ">
                Receba treinamento e suporte para se tornar um especialista em
                soluções de energia elétrica.
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
