import React from "react";
import Footer from "../../components/Footer";
import Nav from "../../components/Nav";
import ScrollToTop from "../../components/ScrollToTop";
import Contato from "./components/Contato";
import GerenciarVendas from "./components/GerenciarVendas";
import HeaderEnergyAdvisor from "./components/HeaderEnergyAdvisor";
import PalmaMao from "./components/PalmaMao";
import Perguntas from "./components/Perguntas";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section7 from "./components/Section7";
export default function EnergyAdvisor() {
  return (
    <div className="scrollbar h-sreen">
      <ScrollToTop />
      <div>
        <Nav />
        <HeaderEnergyAdvisor />
        <Section2 />
        <Section3 />
        <GerenciarVendas />
        <PalmaMao />
        <Section4 />
        <Section5 />
        <Perguntas />
        <Section7 />
        <Contato />
        <Footer />
      </div>
    </div>
  );
}
