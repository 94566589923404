//Importação React
import React, { useEffect, useState } from "react";
import { BiArrowFromBottom } from "react-icons/bi";
import { classNames } from "../utils/classNames";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <>
      <div className="fixed bottom-7 right-7 z-20">
        <button
          aria-label="Scroll para o inicio"
          onClick={scrollToTop}
          className={classNames(
            isVisible ? "opacity-100" : "opacity-0",
            "bg-green-500 hover:bg-green-600 focus:ring-green-400 inline-flex items-center rounded-full p-3 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2"
          )}
        >
          <BiArrowFromBottom className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </>
  );
}
