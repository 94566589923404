import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useState } from "react";

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <div className=" mx-auto">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          isOpen={index === openIndex}
          onToggle={() => handleToggle(index)}
        >
          {item.content}
        </AccordionItem>
      ))}
    </div>
  );
};

const AccordionItem = ({ title, isOpen, onToggle, children }) => {
  return (
    <div className="border-b">
      <div
        className={`flex justify-between items-center cursor-pointer p-4 transition-colors duration-300 rounded-lg ${
          isOpen ? "bg-gray-200" : "bg-white"
        }`}
        onClick={onToggle}
      >
        <div className="font-normal text-neutral-800">{title}</div>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-neutral-600" />
        ) : (
          <ChevronDown className="h-5 w-5 text-neutral-600" />
        )}
      </div>
      <div
        className={`overflow-hidden transition-all text-neutral-600 ${
          isOpen ? "max-h-96 opacity-100 p-4" : "max-h-0 opacity-0"
        }`}
      >
        {isOpen && children}
      </div>
    </div>
  );
};

export default Accordion;
