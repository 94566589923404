import { Field, Formik } from "formik";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Input from "../../../../_layout/_partials/controls/forms/Input";
import { Telefone } from "../../../../_layout/_partials/controls/forms/Telefone";
import { enviarContato } from "../../../_redux/contato/contatoCrud";

export function retirarMascaraCEP(valor) {
  return valor.replace(/[().\s-]+/g, "");
}

export default function Contato() {
  // const validacao = Yup.object().shape({
  //   nome: Yup.string().required("Nome é obrigatório"),
  //   email: Yup.string()
  //     .email("E-mail inválido")
  //     .required("E-mail é obrigatório"),
  //   celular: Yup.string().required("Celular é obrigatório")
  // });

  const notify = () =>
    toast.success("E-mail enviado!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

  return (
    <>
      <section className="px-6 lg:px-14 2xl:px-48 py-10 w-full mb-20 mt-10 bg-gray-50">
        <div className="flex flex-col md:flex-row justify-between gap-6 items-center gap-">
          <div className="flex-col justify-center items-center gap-14 inline-flex">
            <h2 className="text-neutral-500 text-4xl md:text-5xl font-bold !leading-tight">
              <span className="text-acesso">
                Seja um energy advisor parceiro e aumente sua renda{" "}
              </span>
              com o mercado de energia
            </h2>
          </div>
          <Formik
            initialValues={{
              nome: "",
              email: "",
              celular: "",
              mensagem: ""
            }}
            onSubmit={(values, { resetForm }) => {
              values.celular = retirarMascaraCEP(values.celular);
              enviarContato(values);
              resetForm({});
            }}
            // validationSchema={validacao}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              resetForm,
              touched,
              errors
            }) => (
              <div className="lg:min-w-[520px]">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 gap-2">
                    <h3 className="text-neutral-500 text-2xl md:text-3xl font-bold ">
                      Preencha nosso formulário para iniciar seu processo de
                      aprovação
                    </h3>
                    <Field
                      name="nome"
                      component={Input}
                      label="Nome*"
                      placeholder="Digite o nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                    />
                    <Field
                      name="cpf"
                      component={Input}
                      label="CPF*"
                      placeholder="XXX.XXX.XXX-XX"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cpf}
                    />
                    <Field
                      name="celular"
                      component={Telefone}
                      label="Celular*"
                      placeholder="(XX) XXXXX-XXXX"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.celular}
                    />

                    <Field
                      name="email"
                      component={Input}
                      label="E-mail*"
                      placeholder="email@email.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />

                    {/* <div>
                      <label
                        htmlFor="mensagem"
                        className="text-gray-500 text-sm font-semibold"
                      >
                        Mensagem
                      </label>
                      <textarea
                        name="mensagem"
                        cols="10"
                        rows="6"
                        placeholder="Escreva uma mensagem personalizada para gente..."
                        label="Mensagem"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mensagem}
                        className={
                          "w-full pl-3 pr-6 mt-2 p-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue placeholder:text-gray-400 "
                        }
                      ></textarea>
                    </div> */}
                  </div>
                  <div className="mt-4">
                    {values.nome === "" ||
                    values.email === "" ||
                    values.celular === "" ||
                    (errors.email && touched.email) ? (
                      <button
                        // type="submit"
                        className="relative flex w-full group items-center justify-center py-2.5 px-6 bg-neutral-500 rounded text-white text-lg font-nunito font-bold outline-none focus:ring-4 focus:ring-gray-400 shadow-lg transform active:scale-x-75 transition-transform hover:bg-zinc-800"
                      >
                        <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-full group-hover:h-24 opacity-10"></span>
                        Enviar
                      </button>
                    ) : (
                      <button
                        // type="submit"
                        // onClick={notify}
                        className="relative flex w-full  group items-center justify-center py-2.5 px-6 bg-neutral-500 rounded text-white text-lg font-nunito font-bold outline-none focus:ring-4 focus:ring-gray-400 shadow-lg transform active:scale-x-75 transition-transform hover:bg-zinc-800"
                      >
                        <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-full group-hover:h-24 opacity-10"></span>
                        Enviar
                      </button>
                    )}
                    <p className="text-zinc-700 text-opacity-60 text-sm font-normal font-['Nunito Sans'] leading-none mt-3">
                      Clicando no botão “Enviar”, você concorda com nossos{" "}
                      <a
                        href="/politica-privacidade"
                        className="text-zinc-700 text-opacity-60 text-sm font-medium font-['Nunito Sans'] underline leading-snug"
                      >
                        termos de uso e política de privacidade
                      </a>
                      .
                    </p>
                  </div>
                </form>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                ></ToastContainer>
              </div>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}
