import React from "react";

export default function Section2() {
  return (
    <>
      <section className="bg-gray-50 bg-cover flex flex-col justify-center items-center px-4 lg:px-8 py-10">
        <div className="mb-8 flex flex-col items-center justify-center mt-10">
          <h2 className="text-4xl md:text-5xl font-bold font-nunito text-neutral-500 lg:leading-tight mb-2.5 text-center">
            Sua oportunidade de
            <span className="text-acesso"> ganhar uma renda extra</span>{" "}
            ajudando empresas a economizarem na conta de luz
          </h2>
          <p className="text-neutral-600 font-roboto text-base lg:text-lg text-center ">
            Mais de 80% das empresas pagam mais que o necessário na conta de
            luz. Como um energy advisor parceiro, você as ajuda a economizar,
            vendendo energia no mercado livre. Além da redução na conta, as
            empresas ganham por utilizarem energias renováveis, reduzindo a
            emissão de gases de efeito estufa. E você aumenta sua renda com
            nossa comissão de venda.
          </p>
        </div>
        <picture>
          <img
            src="/images/bg-section-2.png"
            alt="Section 2"
            className="w-full"
          />
        </picture>
      </section>
    </>
  );
}
