import React from "react";

export default function Section3() {
  return (
    <>
      <section className="bg-gray-50 bg-cover flex flex-col justify-center items-center px-4 lg:px-8 py-10">
        <div className="mb-8 flex flex-col items-center justify-center mt-10">
          <h2 className="text-3xl md:text-5xl font-bold font-nunito text-neutral-500 lg:leading-tight mb-2.5 text-center">
            Explore a Jornada do Energy Advisor:{" "}
            <br className="hidden md:block" />
            <span className="text-acesso">
              Estratégias para Prospectar e Qualificar Leads!
            </span>
          </h2>
          <p className="text-neutral-600 font-roboto text-base lg:text-lg text-center ">
            Faça seu cadastro agora mesmo e mantenha-se atualizado com todas as
            inovações que estamos implementando. Esteja à frente no mercado e
            prepare-se para se tornar um Energy Advisor.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-10">
          <div className="px-10 py-14 bg-acesso rounded-3xl shadow flex flex-col items-center justify-start gap-6">
            <h3 className="text-center text-white text-3xl font-extrabold leading-10">
              Relevância
            </h3>
            <p className="text-center text-white text-xl font-normal  leading-normal">
              A energia é um dos principais custos na cadeia de valor de uma
              empresa. Geralmente só perde para a folha de pagamentos dos
              funcionários.
            </p>
          </div>
          <div className="px-10 py-14 bg-acesso rounded-3xl shadow flex flex-col items-center justify-start gap-6">
            <h3 className="text-center text-white text-3xl font-extrabold leading-10">
              Timing
            </h3>
            <p className="text-center text-white text-xl font-normal  leading-normal">
              O mercado de energia vive um momento de disrupção semelhante ao da
              telefonia e do setor financeiro, onde o cliente tem acesso a
              vários produtos que reduzem os seus custos com energia.
            </p>
          </div>
          <div className="px-10 py-14 bg-acesso rounded-3xl shadow flex flex-col items-center justify-start gap-6">
            <h3 className="text-center text-white text-3xl font-extrabold leading-10">
              Valor Real
            </h3>
            <p className="text-center text-white text-xl font-normal  leading-normal">
              Os produtos de energia irão agregar valor ao seu negócio e
              aumentarão o ticket médio da sua carteira de clientes, junto com
              uma nova fonte de receita pra você.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
