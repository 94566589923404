import React from "react";
import MyAccordion from "../../../components/Accordion";

export default function Perguntas() {
  const items = [
    {
      title: "O que é um energy advisor?",
      content: "Conteúdo."
    },
    {
      title: "O que um energy advisor vende?",
      content: "Conteúdo"
    },
    {
      title: "Qual é o valor da comissão?",
      content: "Conteúdo."
    },
    { title: "Quando a comissão é paga?", content: "Conteúdo" },
    {
      title: "Como é o processo para se tornar um energy advisor?",
      content: "Conteúdo"
    }
  ];

  return (
    <div className="px-4 lg:px-8 py-20">
      <h2 className="text-3xl md:text-4xl font-bold font-nunito text-neutral-500 lg:leading-tight mb-5 flex items-center justify-center">
        Perguntas frequentes
      </h2>
      <MyAccordion items={items} />
    </div>
  );
}
